import Image from 'next/image';

import {
  SectionContainer,
  LeftAside,
  RightAside,
  Subtitle,
  Title,
  Description,
  SearchContainer,
  Tags,
  StyledInput,
  InputContainer,
  StyledButton,
  StyledLink,
  StyledVideo,
} from './NewWayToSearch.styles';
import { Search } from '@mui/icons-material';
import { useRouter } from 'next/navigation';
import { eventHomepageSearchButtonClicked } from '@/lib/seo/GAEvents';

export const NewWayToSearch = () => {
  const router = useRouter();
  return (
    <SectionContainer id="new-way-to-search">
      {/* Left Side */}
      <LeftAside>
        {/* Title */}
        {/* Subtitle */}
        <Subtitle>Bem vindo à Bamboo Stock</Subtitle>
        <Title>
          Um banco <br /> de vídeos cheio de brasilidade
        </Title>
        {/* Description */}
        <Description>
          Crie filmes incríveis e repletos de diversidade com imagens de
          artistas brasileiros. Diga adeus à imagens gringas nos seus
          comerciais.
        </Description>
        {/* Search Component */}
        <SearchContainer
          onSubmit={(data) => {
            try {
              data.preventDefault();
              const formData = new FormData(data.target as HTMLFormElement);
              const term = formData.get('search_text')?.toString();
              if (!term) return;
              eventHomepageSearchButtonClicked(term);
              router.push(`/search?search_text=${term}`);
            } catch (err) {
              console.warn(err);
            }
          }}
        >
          <InputContainer>
            <Search style={{ color: '#969696', fontSize: '22px' }} />
            <StyledInput
              name="search_text"
              type="text"
              placeholder="Pesquise por Frevo, Bahia, Agro"
            />

            <StyledButton type="submit" variant="contained">
              Buscar
            </StyledButton>
          </InputContainer>
          <Tags>
            Principais tags:{' '}
            {tags.map((tag, index) => (
              <StyledLink href={`/search?search_text=${tag}`} key={tag + index}>
                {tag}
                {index < tags.length - 1 && ', '}
              </StyledLink>
            ))}
          </Tags>
        </SearchContainer>
      </LeftAside>

      {/* Right Side */}
      <RightAside>
        {/* Image */}

        <StyledVideo
          autoPlay
          loop
          muted
          controls={false}
          playsInline
          style={{ pointerEvents: 'none' }}
        >
          <source
            src="https://bamboo-ecommerce-public.nyc3.cdn.digitaloceanspaces.com/static/bmb_home_v13.mov"
            type="video/mp4"
          />
        </StyledVideo>
      </RightAside>
    </SectionContainer>
  );
};

const tags = ['praia', 'drone', 'sorriso', 'cidades'];
